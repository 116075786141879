<template>
  <div class="NoficationModal">
    <div
      v-if="icon === 'warning'"
      class="IconWrap">
      <WarningIcon />
    </div>

    <span
      v-if="title"
      class="Title">{{ title }}</span>

    <div class="TextWrap">
      <span
        v-for="text in texts"
        :key="text"
        v-html="text"/>
    </div>

    <div class="ButtonWrap">
      <Button
        :text="mixWB(buttonTranslation)"
        :isLoading="isPending"
        @button-click="onButtonClick" />
    </div>
  </div>
</template>

<script>
import WarningIcon from '@/assets/svg/warning.svg?inline'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'NoficationModal',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    texts: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: 'warning', // '' | 'warning'
    },
    isPending: {
      type: Boolean,
      default: false,
    },
    buttonTranslation: {
      type: String,
      default: 'OK',
    },
  },
  methods: {
    onButtonClick() {
      this.$emit('close-dialog')
    },
  },
  components: {
    WarningIcon,
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .NoficationModal
    display block
    padding-top 15px

  .IconWrap
    box(60px)
    margin 20px auto 25px
    svg
      fill $color_warning

  .Title
    modalTitle()

  .TextWrap
    padding 0px 20px 30px
    span
      text-align center
      font-size 1.25rem
      padding-bottom 10px

  .ButtonWrap
    display flex
    justify-content center
    >>> .ButtonWrap
      width 50%
</style>

<style lang="stylus">
 .NoficationModal
    .TextWrap a
      text-decoration underline
      white-space pre
</style>
