var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"RequisitionFlow"},[_c('div',{staticClass:"Top"},[_c('h1',{staticClass:"Title"},[_vm._v(_vm._s(_vm.mixWB('CREATE_REQUISITION')))]),_c('div',{staticClass:"CaseMeta"},[_c('span',[_vm._v(_vm._s(_vm.currentScreeningData.getAddressLine()))]),_c('span',[_vm._v(_vm._s(_vm.currentScreeningData.caseNumber))])]),_c('div',{staticClass:"StepNavigation"},[_c('router-link',{staticClass:"Step",class:{
          IsCompleted: _vm.$route.name === 'RequisitionApprove',
        },attrs:{"to":{ name: 'RequisitionCreation' }}},[_c('span',{staticClass:"Number"},[_vm._v("1")]),_c('span',{staticClass:"Text"},[_vm._v(_vm._s(_vm.mixWB('ADJUST_REQUISITION')))])]),_c('div',{staticClass:"StepSeparator"}),_c('router-link',{staticClass:"Step",class:{
          IsDisabled: !_vm.isRequisitionPreviewAvailable
        },attrs:{"to":{ name: 'RequisitionApprove' }}},[_c('span',{staticClass:"Number"},[_vm._v("2")]),_c('span',{staticClass:"Text"},[_vm._v(_vm._s(_vm.mixWB('REQUISITION_SEND')))])])],1)]),_c('router-view'),_c('Dialog',{attrs:{"isShowing":_vm.dialog.showNotification,"showCloseButton":!_vm.requisitionIsSending,"useComponent":_vm.dialog.component,"easyClose":false,"componentProps":{
      title: _vm.dialog.title,
      texts: _vm.dialog.texts,
      icon: _vm.dialog.icon,
      isPending: _vm.requisitionIsSending,
      buttonTranslation: _vm.dialog.buttonTranslation,
    }},on:{"close":_vm.onNotificationClose}}),_c('div',{staticClass:"Footer"},[(_vm.$route.name === 'RequisitionCreation')?[_c('Button',{attrs:{"text":_vm.mixWB('BACK_TO_THE_OVERVIEW'),"fullWidth":false,"path":{ name: 'ResultRequisition' }}}),_c('Button',{attrs:{"text":_vm.mixWB('NEXT'),"fullWidth":false,"isDisabled":!_vm.isRequisitionPreviewAvailable,"path":{ name: 'RequisitionApprove' }}})]:_vm._e(),(_vm.$route.name === 'RequisitionApprove')?[_c('Button',{attrs:{"text":_vm.mixWB('BACK'),"fullWidth":false,"path":{ name: 'RequisitionCreation' }}}),(_vm.currentUser.roles.includes('developer'))?_c('Button',{staticClass:"SendButton",attrs:{"text":_vm.mixWB('TEST'),"isLoading":_vm.requisitionIsSending,"isDisabled":!_vm.isRequisitionPreviewAvailable || _vm.isDemoUser || !_vm.hasNetworkConnection,"fullWidth":false},on:{"button-click":function($event){return _vm.approveAndSend(true)}}}):_vm._e(),_c('div',{staticClass:"SendButtonWrap"},[(!_vm.hasNetworkConnection)?_c('span',[_vm._v(_vm._s(_vm.mixWB('NO_NETWORK_CONNECTION')))]):_vm._e(),_c('Button',{staticClass:"SendButton",attrs:{"text":_vm.mixWB('REQUISITION_SEND'),"isLoading":_vm.requisitionIsSending,"isDisabled":!_vm.isRequisitionPreviewAvailable || _vm.isDemoUser || !_vm.hasNetworkConnection,"fullWidth":false},on:{"button-click":function($event){return _vm.approveAndSend()}}})],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }