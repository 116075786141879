<template>
  <div class="RequisitionFlow">
    <!-- Flow oversigt -->
    <div class="Top">
      <h1 class="Title">{{ mixWB('CREATE_REQUISITION') }}</h1>
      <div class="CaseMeta">
        <span>{{ currentScreeningData.getAddressLine() }}</span>
        <span>{{ currentScreeningData.caseNumber }}</span>
      </div>

      <div class="StepNavigation">
        <router-link
          class="Step"
          :class="{
            IsCompleted: $route.name === 'RequisitionApprove',
          }"
          :to="{ name: 'RequisitionCreation' }">
          <span class="Number">1</span>
          <span class="Text">{{ mixWB('ADJUST_REQUISITION') }}</span>
        </router-link>
        <div class="StepSeparator"/>
        <router-link
          class="Step"
          :class="{
            IsDisabled: !isRequisitionPreviewAvailable
          }"
          :to="{ name: 'RequisitionApprove' }">
          <span class="Number">2</span>
          <span class="Text">{{ mixWB('REQUISITION_SEND') }}</span>
        </router-link>
      </div>
    </div>

    <!-- Step route -->
    <router-view/>

    <!-- Notification dialog for send feedback -->
    <Dialog
      :isShowing="dialog.showNotification"
      :showCloseButton="!requisitionIsSending"
      :useComponent="dialog.component"
      :easyClose="false"
      :componentProps="{
        title: dialog.title,
        texts: dialog.texts,
        icon: dialog.icon,
        isPending: requisitionIsSending,
        buttonTranslation: dialog.buttonTranslation,
      }"
      @close="onNotificationClose" />

    <!-- Step navigation -->
    <div class="Footer">
      <template v-if="$route.name === 'RequisitionCreation'">
        <Button
          :text="mixWB('BACK_TO_THE_OVERVIEW')"
          :fullWidth="false"
          :path="{ name: 'ResultRequisition' }"/>

        <Button
          :text="mixWB('NEXT')"
          :fullWidth="false"
          :isDisabled="!isRequisitionPreviewAvailable"
          :path="{ name: 'RequisitionApprove' }"/>
      </template>

      <template v-if="$route.name === 'RequisitionApprove'">
        <Button
          :text="mixWB('BACK')"
          :fullWidth="false"
          :path="{ name: 'RequisitionCreation' }"/>

        <!-- Only show for developers -->
        <Button
          v-if="currentUser.roles.includes('developer')"
          class="SendButton"
          :text="mixWB('TEST')"
          :isLoading="requisitionIsSending"
          :isDisabled="!isRequisitionPreviewAvailable || isDemoUser || !hasNetworkConnection"
          :fullWidth="false"
          @button-click="approveAndSend(true)" />

        <div class="SendButtonWrap">
          <span v-if="!hasNetworkConnection">{{ mixWB('NO_NETWORK_CONNECTION') }}</span>

          <Button
            class="SendButton"
            :text="mixWB('REQUISITION_SEND')"
            :isLoading="requisitionIsSending"
            :isDisabled="!isRequisitionPreviewAvailable || isDemoUser || !hasNetworkConnection"
            :fullWidth="false"
            @button-click="approveAndSend()" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'
import { mapActions, mapGetters } from 'vuex'
import * as Sentry from '@sentry/browser'
import Dialog from '@/components/Modals/Dialog.vue'
import NoficationModal from '@/components/Modals/NoficationModal.vue'
import { mixBeforeUnload } from '@/globals/javascript/_util/mixins'

export default {
  name: 'RequisitionFlow',
  mixins: [mixBeforeUnload({
    showPrompt: 'showBeforeLeavePrompt',
    callbackOnLeave: 'resetRequisitionFlow',
  })],
  data() {
    return {
      states: {
        success: false,
        error: false,
      },
      dialog: {
        showNotification: false,
        component: NoficationModal,
        title: '',
        texts: [],
        icon: '',
        isPending: false,
        buttonTranslation: 'BACK_TO_THE_OVERVIEW',
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningData',
      'isDemoUser',
      'currentUser',
      'hasNetworkConnection',
      'isRequisitionPreviewAvailable',
      'requisitionIsSending',
      'requisitionPickUpText',
    ]),
    showBeforeLeavePrompt() {
      return this.isRequisitionPreviewAvailable && !this.states.success
    },
  },
  methods: {
    ...mapActions([
      'resetRequisitionFlow',
      'processRequisition',
      'setIsSendingRequisition',
    ]),
    async compilePDFHTML() {
      // Grap requisition pages without web presentation wrappers
      const content = [...this.$el.querySelectorAll('.Page')].map((page) => page.outerHTML)

      // Grap all link and style tags from <head> to retain page design
      const headStyles = [...document.head.querySelectorAll('style')]

      const cssLink = [...document.head.querySelectorAll('link[href]')].reduce((prev, link) => {
        const href = link.getAttribute('href')
        if (href.indexOf('.css') !== -1) {
          prev.push(fetch(window.location.origin + href))
        }

        return prev
      }, [])

      const response = await Promise.all(cssLink)
      const cssContent = await Promise.all([
        ...response.map((res) => res.text()),
      ])

      const headContent = [
        ...headStyles.map((x) => x.innerHTML),
        ...cssContent,
      ].map((content) => `<style>${ content }</style>`).join('')

      // Compile PDF HTML
      let pdfDoc = '<!DOCTYPE html>'
      pdfDoc += '<html>'
      pdfDoc += '<head>'
      pdfDoc += headContent
      pdfDoc += '</head>'
      pdfDoc += '<body>'
      pdfDoc += content.join('')
      pdfDoc += '</body>'
      pdfDoc += '</html>'

      return pdfDoc
    },
    async approveAndSend(isDevTest = false) {
      this.setIsSendingRequisition(true)

      this.dialog.title = this.mixWB('PREPARING_THE_REQUISITION')
      this.dialog.texts = [
        this.mixWB('PLEASE_WAIT'),
      ]
      this.dialog.showNotification = true

      const response = await this.processRequisition({
        projectID: this.$router.currentRoute.params.screeningID,
        html: await this.compilePDFHTML(),
        isDevTest,
      })

      if (response.ok) {
        this.states.success = true

        this.dialog.icon = ''
        this.dialog.title = this.mixWB('REQUISITION_IS_SENT_TEXT')
        this.dialog.texts = [
          this.mixWB('REQUISITION_SENT_SUCCESS_INFO_TEXT'),
          this.requisitionPickUpText,
        ]
      }
      else {
        this.states.error = true

        this.dialog.icon = 'warning'
        this.dialog.title = ''
        this.dialog.texts = [
          this.mixWB('AN_ERROR_OCCURRED_PLEASE_TRY_AGAIN_LATER'),
          this.mixWB('CONTACT_SUPPORT_IF_THE_PROBLEM_PERSISTS'),
        ]

        Sentry.captureException(response)
      }

      this.setIsSendingRequisition(false)
    },
    onNotificationClose() {
      this.dialog.showNotification = false

      if (!this.states.success) {
        this.states.success = false
        this.states.error = false
        return
      }

      this.$router.push({ name: 'ResultRequisition', params: { screeningID: this.$route.params.screeningID } })
    },
  },
  components: { Button, Dialog },
}
</script>

<style lang="stylus" scoped>
  .RequisitionFlow
    padding 20px
    +below($tablet)
      padding 15px
    +below($phablet)
      padding 10px
    > *
      max-width 1200px
      width 100%
      margin-left auto
      margin-right auto
    .Top ~ *
      margin-top 30px

  .Top
    display flex
    flex-direction column
    align-items center
    text-align center
    padding-bottom 30px
    border-bottom 1px solid $color_grey_lighter
    .StepNavigation
      margin-top 15px
    .CaseMeta
      > * + *
        margin-top 2px

  .StepNavigation
    display flex
    align-items center
    justify-content center
    width: 100%
    padding-left 50px
    padding-right 50px
    padding-bottom 1.25em // default line-height

  .StepSeparator
    max-width 240px
    width 100%
    flex-shrink 1
    border-bottom 1px solid $color_grey_light

  .Step
    position relative
    display flex
    flex-direction column
    align-items center
    text-align center
    .Number
      display flex
      align-items center
      justify-content center
      text-align center
      circle(40px)
      color inherit
      background-color $color_grey_lighter
      border 2px solid darken($color_grey_lighter, 10%)
    .Text
      position absolute
      top 100%
      white-space nowrap
      color inherit
    &.IsDisabled
      pointer-events none
      cursor default
    &.IsCompleted,
    &.router-link-exact-active
      color $color_grey_dark
      .Number
        background-color $color_online_green
    &.router-link-exact-active .Number
      border-color $color_grey

  .Footer
    position sticky
    bottom 0
    z-index 50
    display flex
    justify-content space-between
    align-items flex-end
    padding 15px 0 5px
    background-color $color_grey_lightest
    border-top 1px solid $color_grey_lighter
    .SendButtonWrap
      display flex
      flex-direction column
      align-items flex-end
      position relative
      text-align right
      span
        margin-bottom 5px
    &:before
      content ''
      position absolute
      display block
      top 0
      bottom 0
      left -10px
      right -10px
      background-color $color_grey_lightest

</style>
